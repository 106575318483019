var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loader)?_c('load',{attrs:{"color":"#2474b2","size":"70"}}):_c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.loginStep($event)}}},[_c('div',{staticClass:"col-md-12 mt-3 mb-1"},[_c('label',{staticClass:"label_default mb-0"},[_vm._v(" Qual seu "),_c('strong',[_vm._v("CPF ou CNPJ ?")])])]),_c('ValidationProvider',{staticClass:"col-md-12 my-1 mb-3",attrs:{"rules":{ required: true, min:_vm.CPF.length <= 11 ? 11 : 14 },"id":"loginInputContainer","name":"CPF","tag":"div","bails":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
var validate = ref.validate;
return [_c('the-mask',{staticClass:"input_default form-control",class:classes,attrs:{"type":"text","id":"inputLogin","mask":['###.###.###-##', '##.###.###/####-##'],"placeholder":"...","masked":false},nativeOn:{"blur":function($event){return validate($event)}},model:{value:(_vm.CPF),callback:function ($$v) {_vm.CPF=$$v},expression:"CPF"}}),_c('div',[_c('div',{staticClass:"btn_default px-2 py-2",attrs:{"type":"submit","disabled":_vm.disableSpinnerButtonCpf,"id":"buttonCpfLogin"},on:{"click":function($event){return _vm.getUserWithCpf()}}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.disableSpinnerButtonCpf),expression:"disableSpinnerButtonCpf"}],staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}}),_vm._v(" "+_vm._s(!_vm.disableSpinnerButtonCpf ? ">" : "")+" ")])])]}}])}),_c('div',{staticClass:"col-md-12 mt-2 mb-0"},[_c('label',{staticClass:"label_default mb-0"},[_vm._v(" Escolha a "),_c('strong',[_vm._v("Unidade")])])]),_c('ValidationProvider',{staticClass:"mb-2 mt-2",attrs:{"rules":"required","name":"Unidade","tag":"div","bails":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.unidadeSelect),expression:"unidadeSelect"}],staticClass:"select-login input_default form-control",class:classes,attrs:{"id":"selectLogin","disabled":_vm.disabledUnidade},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.unidadeSelect=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.selectUnidade]}},[_c('option',{directives:[{name:"show",rawName:"v-show",value:(_vm.isAdmin),expression:"isAdmin"}],domProps:{"value":{tenancyName:'master',id:0}}},[_vm._v("MASTER")]),_vm._l((_vm.unidades),function(item,index){return _c('option',{key:index,domProps:{"value":item}},[_vm._v(_vm._s(item.tenancyName))])})],2),_c('span',_vm._b({class:classes},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}])}),_c('div',{staticClass:"col-md-12 mb-2 mt-3",attrs:{"id":"orderKeyCont"}},[_c('label',{staticClass:"label_default mb-0"},[_vm._v(" Digite sua "),_c('strong',[_vm._v("Senha")])])]),_c('ValidationProvider',{staticClass:"mb-3",attrs:{"rules":"required|min:5","name":"Senha","tag":"div","bails":false,"disabled":_vm.disabled},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var ariaMsg = ref.ariaMsg;
var classes = ref.classes;
return [_c('div',{staticClass:"d-none",staticStyle:{"margin-bottom":"10px"},attrs:{"id":"msg"}},[_c('span',{staticClass:"alerta",staticStyle:{"font-size":"14px"}},[_vm._v("O Caps-Lock está ativado!")])]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.senha),expression:"senha"}],staticClass:"px-2 py-2 input_default form-control",class:classes,attrs:{"type":"password","placeholder":"****","disabled":_vm.disabledInputPassword,"id":"txtSenha"},domProps:{"value":(_vm.senha)},on:{"input":function($event){if($event.target.composing){ return; }_vm.senha=$event.target.value}}}),_c('span',_vm._b({class:classes},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]}}])}),_c('div',[_c('button',{staticClass:"btn_default px-2 py-2",attrs:{"type":"submit","disabled":_vm.disableSpinnerButtonLogin,"id":"buttonSubmitLogin"}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.disableSpinnerButtonLogin),expression:"disableSpinnerButtonLogin"}],staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}}),_vm._v(" "+_vm._s(!_vm.disableSpinnerButtonLogin ? "Entrar" : "")+" ")])]),_c('div',{staticClass:"col-md-12 my-1"},[_c('p',{staticClass:"esqueci_senha"},[_vm._v(" Esqueceu a senha? "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.esqueciSenha()}}},[_vm._v("Clique aqui.")])])]),_c('div',{staticClass:"routeCont"},[(this.sizeWindow < 768)?_c('router-link',{staticClass:"outra-conta",attrs:{"to":'/unidadeCpf'}},[_vm._v(" "+_vm._s(_vm.$route.path !='/logout' ?'Não é você?' :'Ficou com saudades?')+" "),_c('strong',[_vm._v(_vm._s(_vm.$route.path !='/logout' ?'Entre com outra conta.' : 'Entre novamente aqui.'))])]):_vm._e()],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }